import React from 'react';
import { Col } from 'react-bootstrap';

const ProjectExperience = props => {
  const { title, description, image } = props;
  return (
    <Col xs={11} md={3} className="my-2 my-md-3 text-center text-md-left">
      <div className="text-center project-icon">
        <img
          style={{ height: '80px', width: '80px' }}
          className="mx-auto p-2"
          src={image.publicURL}
          alt={title}
        />
        <h6 id="project-title" className="text-appcolor text-bold pt-2">
          {title}
        </h6>
        <p id="project-description" className="text-muted">
          {description}
        </p>
      </div>
    </Col>
  );
};

export default ProjectExperience;
