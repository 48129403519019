import React from 'react';
import { Col } from 'react-bootstrap';

const InternalExperience = props => {
  const { title, description, image } = props;
  return (
    <Col xs={11} md={4} className="my-2 my-md-3 text-center text-md-left">
      <div className="mx-auto w-75 text-center internal-icon">
        <img
          style={{ height: '80px', width: '80px' }}
          className="mx-auto p-2"
          src={image.publicURL}
          alt={title}
        />
        <h6 id="internal-title" className="text-appcolor text-bold pt-2">
          {title}
        </h6>
        <p id="internal-description" className="text-muted">
          {description}
        </p>
      </div>
    </Col>
  );
};

export default InternalExperience;
