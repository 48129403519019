import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_our-opportunity-career-path.scss';
import ProjectExperience from '../components/OurOpportunityCareerPath/ProjectExperience';
import InternalExperience from '../components/OurOpportunityCareerPath/InternalExperience';
import Plus from '../../static/assets/Plus_blue.svg';
import PlusHover from '../../static/assets/Plus_white.svg';
import Minus from '../../static/assets/minus_white.svg';
import Yellowkey from '../../static/assets/yellow_key.svg';
import YellowkeySecond from '../../static/assets/career_path/yellow_key_second.svg';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import * as globalAnimation from '../animations/globalAnimation';
import {
  animateAccordionSection,
  animateImageBottomRight,
  animateImageRight,
  animateAccodion,
  animateImageRightx,
} from '../animations/CareerPath';
import StepperSection from '../components/OurOpportunityCareerPath/StepperSection';

export default class whatWillMyCareerPath extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayIcon1: false,
      displayIcon2: false,
      displayIcon3: false,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
  }

  animateSectionOne = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateImageRightx(
        tl,
        '.svg-line__line_yellow_two',
        '.svg_circle_yellow_two',
        '#title-one',
        '.career-image',
        '.lightblue_bg',
        '#description-one',
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '70px',
        '-70px',
        '#description-two'
      );
    }
  };

  animateSectionTwo = inView => {
    const tl = new TimelineLite();
    if (inView) {
      globalAnimation.animateHeading(tl, '.project-title', '.project-subtitle');
      globalAnimation.ScaleIcons(tl, '.project-icon', '#project-title', '#project-description');
    }
  };

  animateInternalIcons = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      globalAnimation.animateHeading(tl, '.internal-title', '.internal-subtitle');
      globalAnimation.ScaleIcons(tl, '.internal-icon', '#internal-title', '#internal-description');
    }
  };

  animateSectionThree = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateAccordionSection(tl, '#accordion-title', '.accordion');
    }
  };

  animateAccordionOne = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateAccodion(
        tl,
        '.accordion-text',
        '.accordion-one-icon',
        '#accordion-one-title',
        '#accordion-one-description'
      );
    }
  };

  animateAccordionTwo = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateAccodion(
        tl,
        '.accordion-two-icon',
        '.accordion-text-two',
        '#accordion-two-title',
        '#accordion-two-description'
      );
    }
  };

  animateAccordionThree = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateAccodion(
        tl,
        '.accordion-three-icon',
        '.accordion-text-three',
        '#accordion-three-title',
        '#accordion-three-description'
      );
    }
  };

  animateSectionFive = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateImageBottomRight(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#title-five',
        '#description-five',
        '.career-image2',
        '.whitesmoke_bg',
        '70px',
        '70px'
      );
    }
  };

  animateSectionSix = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      animateImageRight(
        tl,
        '.svg-line__line_yellow_two',
        '.svg_circle_yellow_two',
        '#title-six',
        '.career-image3',
        '.appcolor_bg',
        '#description-six',
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '70px',
        '70px'
      );
    }
  };

  render() {
    const { displayIcon1, displayIcon2, displayIcon3 } = this.state;
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            sectionOne,
            sectionTwo,
            sectionThree,
            projectExperience,
            internalExperience,
            accordionSection,
            accordionSection: { accordionOne, accordionTwo, accordionThree },
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="What will my career path be?" />
        <Container fluid="true" className="section-one main_wrapper">
          <Row className="bg-appcolor">
            <Col xs={12} className="">
              <div className="wrapper bg-appcolor px-0">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title white">
                    {title}
                  </h3>
                  <h4 className="text-uppercase page_heading_child subtitle text-white">{title}</h4>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              {/* <CareerStepper /> */}
              <StepperSection />
            </Col>
          </Row>

          <Observer onChange={this.animateSectionOne} triggerOnce>
            <Row className="main_wrapper mb-lg-4">
              <Col xs={12} className="d-lg-none">
                <Img
                  className="img-fluid cover_pic square"
                  fluid={sectionOne.image.childImageSharp.fluid}
                />
              </Col>
              <Col xs={12} lg={{ span: 4, offset: 1 }} className="d-none d-lg-block">
                <div className="lightblue_bg square mx-auto my-lg-5">
                  <div className="bg-appcolor">
                    <Img
                      className="img-fluid career-image cover_pic square"
                      fluid={sectionOne.image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={{ span: 5, offset: 1 }}>
                <div className="my-lg-5 my-3">
                  <h5 id="title-one" className="text-appcolor text-bold">
                    {sectionOne.title}
                  </h5>
                  <div className="position-relative line line-horizontal">
                    <YellowkeySecond className="mb-0" />
                  </div>
                  <p id="description-one">{sectionOne.descriptionOne}</p>
                  <div className="position-relative line line-horizontal">
                    <LightBluekey className="mb-0" />
                  </div>
                  <p id="description-two">{sectionOne.descriptionTwo}</p>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>
        <div className="bg-whitesmoke">
          <Container className="section-two">
            <Observer onChange={this.animateSectionTwo} triggerOnce>
              <Row className="justify-content-around pb-md-5">
                <Col xs={12}>
                  <div className="wrapper">
                    <div className="page_heading_section text-center">
                      <h3 className="page_heading text-uppercase d-lg-block d-none project-title">
                        Project Experience
                      </h3>
                      <h4 className="text-uppercase page_heading_child project-subtitle">
                        Project Experience
                      </h4>
                    </div>
                  </div>
                </Col>
                {projectExperience.map(project => (
                  <ProjectExperience
                    title={project.title}
                    description={project.description}
                    image={project.image}
                  />
                ))}
              </Row>
            </Observer>
          </Container>
        </div>

        <Container className="section-three">
          <Observer onChange={this.animateInternalIcons} triggerOnce>
            <Row>
              <Col xs={12} lg={12} className="main_wrapper">
                <div className="page_heading_section text-center mb-5">
                  <h3 className="page_heading text-uppercase d-lg-block d-none internal-title">
                    Internal Experience
                  </h3>
                  <h4 className="text-uppercase page_heading_child internal-subtitle">
                    Internal Experience
                  </h4>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center justify-content-md-between mb-5">
              {internalExperience.map(internal => (
                <InternalExperience
                  title={internal.title}
                  description={internal.description}
                  image={internal.image}
                />
              ))}
            </Row>
          </Observer>
        </Container>

        <div className="bg-appcolor">
          <Observer onChange={this.animateSectionThree} triggerOnce>
            <Container className="section-four">
              <Row className="justify-content-around bg-appcolor py-5">
                <Col xs={12} lg={3} className="text-white text-bold text-center text-md-left">
                  <h5 id="accordion-title" className="text-bold">
                    {accordionSection.title}
                  </h5>
                </Col>

                <Col xs={12} lg={{ span: 8, offset: 1 }}>
                  <Accordion>
                    <Card className="mb-3">
                      <Accordion.Toggle
                        as={Card.Header}
                        onClick={() => {
                          this.setState({
                            displayIcon1: !displayIcon1,
                            displayIcon2: false,
                            displayIcon3: false,
                          });
                        }}
                        eventKey="0"
                        className={`p-4 ${displayIcon1 ? 'card-header-true' : 'card-header-false'}`}
                      >
                        <div className="px-2 px-md-4 d-flex justify-content-between accordion">
                          <h5 className="text-bold mb-0">{accordionOne.title}</h5>

                          {displayIcon1 === false ? (
                            <div>
                              <Plus
                                style={{ width: '24px', height: '24px' }}
                                className="position-absolute plus d-none d-lg-block"
                                alt=""
                              />
                              <PlusHover
                                style={{ width: '24px', height: '24px' }}
                                className="plus_hover d-none d-lg-block"
                                alt=""
                              />
                              <Plus
                                style={{ width: '24px', height: '24px' }}
                                className="position-absolute plus d-lg-none"
                                alt=""
                              />
                              <PlusHover
                                style={{ width: '24px', height: '24px' }}
                                className="plus_hover d-lg-none"
                                alt=""
                              />
                            </div>
                          ) : null}
                          {displayIcon1 === true ? (
                            <Minus style={{ width: '24px', height: '24px' }} alt="" />
                          ) : null}
                          {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0" className="px-2 px-md-4">
                        <Card.Body>
                          <Observer onChange={this.animateAccordionOne}>
                            <Row>
                              <Col>
                                <p className="text-semibold accordion-text">
                                  {accordionOne.description}
                                </p>
                              </Col>
                            </Row>

                            <Row className="justify-content-around">
                              {accordionOne.exampleOne.map((data, index) => (
                                <Col xs={12} lg={5} key={String(index)}>
                                  <img
                                    className="accordion-one-icon"
                                    src={data.image.publicURL}
                                    height="50px"
                                    alt=""
                                  />
                                  <h6 id="accordion-one-title" className="text-appcolor mt-3">
                                    {data.title}
                                  </h6>
                                  <p id="accordion-one-description" className="text-muted">
                                    {data.description}
                                  </p>
                                </Col>
                              ))}
                            </Row>
                          </Observer>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mb-3">
                      <Accordion.Toggle
                        as={Card.Header}
                        onClick={() => {
                          this.setState({
                            displayIcon2: !displayIcon2,
                            displayIcon1: false,
                            displayIcon3: false,
                          });
                        }}
                        eventKey="1"
                        className={`p-4 ${displayIcon2 ? 'card-header-true' : 'card-header-false'}`}
                      >
                        <div className="px-2 px-md-4 d-flex justify-content-between accordion">
                          <h5 className="text-bold mb-0">{accordionTwo.title}</h5>
                          {displayIcon2 === false ? (
                            <div>
                              <Plus
                                style={{ width: '24px', height: '24px' }}
                                className="position-absolute plus d-none d-lg-block"
                                alt=""
                              />
                              <PlusHover
                                style={{ width: '24px', height: '24px' }}
                                className="plus_hover d-none d-lg-block"
                                alt=""
                              />
                              <Plus
                                style={{ width: '24px', height: '24px' }}
                                className="position-absolute plus d-lg-none"
                                alt=""
                              />
                              <PlusHover
                                style={{ width: '24px', height: '24px' }}
                                className="plus_hover d-lg-none"
                                alt=""
                              />
                            </div>
                          ) : null}
                          {displayIcon2 === true ? (
                            <Minus style={{ width: '24px', height: '24px' }} alt="" />
                          ) : null}
                          {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1" className="px-2 px-md-4">
                        <Card.Body>
                          <Observer onChange={this.animateAccordionTwo}>
                            <Row>
                              <Col>
                                <p className="accordion-text-two text-semibold">
                                  {accordionTwo.description}
                                </p>
                              </Col>
                            </Row>
                            <Row className="justify-content-around">
                              {accordionTwo.exampleTwo.map((data, index) => (
                                <Col xs={12} lg={5} key={String(index)}>
                                  <img
                                    className="accordion-two-icon"
                                    src={data.image.publicURL}
                                    height="50px"
                                    alt=""
                                  />
                                  <h6 id="accordion-two-title" className="text-appcolor mt-3">
                                    {data.title}
                                  </h6>
                                  <p id="accordion-two-description" className="text-muted">
                                    {data.description}
                                  </p>
                                </Col>
                              ))}
                            </Row>
                          </Observer>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        onClick={() => {
                          this.setState({
                            displayIcon3: !displayIcon3,
                            displayIcon1: false,
                            displayIcon2: false,
                          });
                        }}
                        eventKey="2"
                        className={`p-4 ${displayIcon3 ? 'card-header-true' : 'card-header-false'}`}
                      >
                        <div className="px-2 px-md-4 d-flex justify-content-between accordion">
                          <h5 className="text-bold mb-0">{accordionThree.title}</h5>
                          {displayIcon3 === false ? (
                            <div>
                              <Plus
                                style={{ width: '24px', height: '24px' }}
                                className="position-absolute plus d-none d-lg-block"
                                alt=""
                              />
                              <PlusHover
                                style={{ width: '24px', height: '24px' }}
                                className="plus_hover d-none d-lg-block"
                                alt=""
                              />
                              <Plus
                                style={{ width: '24px', height: '24px' }}
                                className="position-absolute plus d-lg-none"
                                alt=""
                              />
                              <PlusHover
                                style={{ width: '24px', height: '24px' }}
                                className="plus_hover d-lg-none"
                                alt=""
                              />
                            </div>
                          ) : null}
                          {displayIcon3 === true ? (
                            <Minus style={{ width: '24px', height: '24px' }} alt="" />
                          ) : null}
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2" className="px-2 px-md-4">
                        <Card.Body>
                          <Observer onChange={this.animateAccordionThree}>
                            <Row>
                              <Col>
                                <p className="text-semibold accordion-text-three">
                                  {accordionThree.description}
                                </p>
                              </Col>
                            </Row>
                            <Row className="justify-content-around">
                              {accordionThree.exampleThree.map((data, index) => (
                                <Col xs={12} lg={5} key={String(index)}>
                                  <img
                                    src={data.image.publicURL}
                                    className="accordion-three-icon"
                                    height="50px"
                                    alt=""
                                  />
                                  <h6 id="accordion-three-title" className="text-appcolor mt-3">
                                    {data.title}
                                  </h6>
                                  <p id="accordion-three-description" className="text-muted">
                                    {data.description}
                                  </p>
                                </Col>
                              ))}
                            </Row>
                          </Observer>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </Container>
          </Observer>
        </div>

        <Container fluid className="section-five">
          <Observer onChange={this.animateSectionFive} triggerOnce>
            <Row>
              <Col xs={12} className="d-lg-none pt-3">
                <Img
                  className="img-fluid cover_pic square"
                  fluid={sectionTwo.image.childImageSharp.fluid}
                />
              </Col>
              <Col xs={12} lg={{ span: 5, offset: 1 }} className="py-lg-5 pt-2">
                <h5 id="title-five" className="text-appcolor text-bold">
                  {sectionTwo.title}
                </h5>
                <div className="position-relative line line-horizontal">
                  <Yellowkey className="mb-0" />
                </div>
                <p id="description-five">{sectionTwo.description}</p>
              </Col>
              <Col xs={12} lg={5} className="d-none d-lg-block">
                <div className="whitesmoke_bg square mx-auto my-lg-5">
                  <div className="bg-whitesmoke">
                    <Img
                      className="img-fluid career-image2 cover_pic square"
                      fluid={sectionTwo.image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.animateSectionSix} triggerOnce>
            <Row className="py-lg-5 my-4">
              <Col xs={12} lg={4} className="d-lg-none">
                <Img
                  className="img-fluid  cover_pic square"
                  fluid={sectionThree.image.childImageSharp.fluid}
                />
              </Col>
              <Col xs={12} lg={{ span: 4, offset: 1 }} className="d-none d-lg-block">
                <div className="bg-appcolor appcolor_bg square mx-auto my-lg-5">
                  <div>
                    <Img
                      className="img-fluid career-image3 cover_pic square"
                      fluid={sectionThree.image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={{ span: 5, offset: 1 }} className="order-1 order-lg-2">
                <div className="my-lg-5">
                  <h5 id="title-six" className="text-appcolor text-bold pt-2 pt-lg-0">
                    {sectionThree.title}
                  </h5>
                  <div className="position-relative line line-horizontal">
                    <YellowkeySecond className="mb-0" />
                  </div>
                  <p id="description-six">{sectionThree.descriptionOne}</p>
                  <div className="position-relative mt-3 line line-horizontal">
                    <LightBluekey className="mb-0" />
                  </div>
                  <p id="description-six">{sectionThree.descriptionTwo}</p>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "career-path" } }) {
      frontmatter {
        title
        sectionOne {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        projectExperience {
          title
          description
          image {
            publicURL
          }
        }
        internalExperience {
          title
          description
          image {
            publicURL
          }
        }
        accordionSection {
          title
          accordionOne {
            title
            description
            exampleOne {
              title
              description
              image {
                publicURL
              }
            }
          }
          accordionTwo {
            title
            description
            exampleTwo {
              title
              description
              image {
                publicURL
              }
            }
          }
          accordionThree {
            title
            description
            exampleThree {
              title
              description
              image {
                publicURL
              }
            }
          }
        }
        sectionTwo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionThree {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
