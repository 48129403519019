import { Power1, Power2 } from 'gsap';

export function animateStepper(tl, stepper) {
  const o = 1;
  return tl.staggerFrom(
    stepper,
    o,
    {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    },
    0,
    o
  );
}

export function animateStepperSection(tl, title, subtitle, description, svgLine, svgCircle, lists) {
  const o = 1.5;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      subtitle,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      lists,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      2
    );
}

export function animateStep(tl, title, subtitle, description, svgLine, svgCircle, lists) {
  const o = 1;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeInOut,
      },
      0,
      o
    )
    .staggerFrom(
      subtitle,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeInOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      lists,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateImageRightx(
  tl,
  svgLineOne,
  svgCircleOne,
  title,
  image,
  container,
  description,
  svgLineTwo,
  svgCircleTwo,
  X,
  Y,
  descTwo
) {
  const o = 1;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 10,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(
      svgLineOne,
      0.3,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleOne,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFrom(description, o, {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    })
    .staggerFromTo(
      svgLineTwo,
      0.3,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleTwo,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to(
      '.line-horizontal',
      0.3,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      0.5
    )
    .staggerFrom(
      container,
      o,
      {
        x: -120,
        y: 0,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      o,
      {
        x: X,
        y: Y,
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(descTwo, 1.5, {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    });
}

export function animateImageRight(
  tl,
  svgLineOne,
  svgCircleOne,
  title,
  image,
  container,
  description,
  svgLineTwo,
  svgCircleTwo,
  X,
  Y
) {
  const o = 1;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 10,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(
      svgLineOne,
      0.3,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleOne,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(
      svgLineTwo,
      0.3,
      { attr: { width: 0 } },
      { attr: { width: 88 }, clearProps: 'all' }
    )
    .staggerFromTo(
      svgCircleTwo,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to(
      '.line-horizontal',
      0.3,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      0.5
    )
    .staggerFrom(description, o, {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    })
    .staggerFrom(
      container,
      o,
      {
        x: -120,
        y: 0,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      o,
      {
        x: X,
        y: Y,
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateAccordionSection(tl, title, accordion) {
  const o = 1;
  return tl
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      accordion,
      o,
      {
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateImageBottomRight(
  tl,
  svgLine,
  svgCircle,
  title,
  description,
  image,
  container,
  X,
  Y
) {
  const o = 1.5;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 10,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      o,
      {
        x: 0,
        y: 0,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerTo(
      image,
      o,
      {
        x: X,
        y: Y,
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateAccodion(tl, text, icon, title, description) {
  return tl
    .staggerFrom(
      text,
      0.5,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .staggerFrom(
      icon,
      0.8,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .staggerFrom(
      title,
      1.1,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    )
    .staggerFrom(
      description,
      1.4,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      0.5
    );
}
