/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'gatsby';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';

import ReadMore from '../../../static/assets/Readmore-white.svg';
import { Row, Col } from 'react-bootstrap';
import YellowKey from '../../../static/assets/yellow_key.svg';
import NxtBtn from '../../../static/assets/career_path/forward.svg';
import PrvBtn from '../../../static/assets/career_path/backward.svg';
import '../../sass/_our-opportunity-career-path.scss';
import { animateHeading } from '../../animations/globalAnimation';
import { animateStepper, animateStepperSection, animateStep } from '../../animations/CareerPath';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import './stepperSection.scss';
let Stepper = '';
export default class StepperSection extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 0,
      clickPrev: false,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    if (window) {
      Stepper = require('bs-stepper');
    }
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    animateStepper(tl, '.bs-stepper-header');

    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });

    this.setState({ currentStep: this.stepper._currentIndex });

    animateStepperSection(
      tl,
      '.stepper-title',
      '.stepper-subtitle',
      '#stapper-description',
      '.svg-line__line_yellow',
      '.svg_circle_yellow',
      '#list'
    );
  }

  animateStep = inView => {
    const { currentStep, clickPrev } = this.state;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView && clickPrev && currentStep === 0) {
      animateStep(
        tl,
        '.stepper-title',
        '.stepper-subtitle',
        '#stapper-description',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#list'
      );
    }
  };

  animateStepOne = inView => {
    const { currentStep } = this.state;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView && currentStep === 1) {
      animateStep(
        tl,
        '.stepper-title',
        '.stepper-subtitle',
        '#stapper-description',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#list'
      );
    }
  };

  animateStepTwo = inView => {
    const { currentStep } = this.state;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView && currentStep === 2) {
      animateStep(
        tl,
        '.stepper-title',
        '.stepper-subtitle',
        '#stapper-description',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#list'
      );
    }
  };

  animateStepThree = inView => {
    const { currentStep } = this.state;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView && currentStep === 3) {
      animateStep(
        tl,
        '.stepper-title',
        '.stepper-subtitle',
        '#stapper-description',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#list'
      );
    }
  };

  animateStepFour = inView => {
    const { currentStep } = this.state;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView && currentStep === 4) {
      animateStep(
        tl,
        '.stepper-title',
        '.stepper-subtitle',
        '#stapper-description',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#list'
      );
    }
  };

  animateStepFive = inView => {
    const { currentStep } = this.state;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView && currentStep === 5) {
      animateStep(
        tl,
        '.stepper-title',
        '.stepper-subtitle',
        '#stapper-description',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '#list'
      );
    }
  };

  onClickNext = () => {
    this.stepper.next();
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };

  onClickPrev = () => {
    this.stepper.previous();
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep - 1,
      clickPrev: true,
    });
  };

  render() {
    const { currentStep } = this.state;

    return (
      <div className="pb-5 px-lg-5">
        <div id="stepper1" className="bs-stepper">
          <div className="d-none d-lg-block px-5">
            <div className="bs-stepper-header">
              <div className="step" data-target="#test-l-1">
                <button
                  className="step-trigger"
                  onClick={() =>
                    this.setState({
                      currentStep: 0,
                      clickPrev: true,
                    })
                  }
                >
                  <span
                    className={`bs-stepper-circle font-weight-normal ${
                      0 < currentStep ? 'pass' : ''
                    }`}
                  >
                    1
                  </span>
                  <span
                    className={`bs-stepper-label font-weight-normal ${
                      0 < currentStep ? 'pass' : ''
                    }`}
                  >
                    Associate
                  </span>
                </button>
              </div>
              <div className={`bsline ${0 < currentStep ? 'pass' : ''}`}></div>
              <div className="step" data-target="#test-l-2">
                <button
                  className="step-trigger"
                  onClick={() =>
                    this.setState({
                      currentStep: 1,
                    })
                  }
                >
                  <span
                    className={`bs-stepper-circle font-weight-normal ${
                      1 < currentStep ? 'pass' : ''
                    }`}
                  >
                    2
                  </span>
                  <span
                    className={`bs-stepper-label font-weight-normal ${
                      1 < currentStep ? 'pass' : ''
                    }`}
                  >
                    Sr. Associate
                  </span>
                </button>
              </div>
              <div className={`bsline ${1 < currentStep ? 'pass' : ''}`}></div>
              <div className="step" data-target="#test-l-3">
                <button
                  className="step-trigger"
                  onClick={() =>
                    this.setState({
                      currentStep: 2,
                    })
                  }
                >
                  <span
                    className={`bs-stepper-circle font-weight-normal ${
                      2 < currentStep ? 'pass' : ''
                    }`}
                  >
                    3
                  </span>
                  <span
                    className={`bs-stepper-label font-weight-normal ${
                      2 < currentStep ? 'pass' : ''
                    }`}
                  >
                    Consultant
                  </span>
                </button>
              </div>
              <div className={`bsline ${2 < currentStep ? 'pass' : ''}`}></div>
              <div className="step" data-target="#test-l-4">
                <button
                  className="step-trigger"
                  onClick={() =>
                    this.setState({
                      currentStep: 3,
                    })
                  }
                >
                  <span
                    className={`bs-stepper-circle font-weight-normal ${
                      3 < currentStep ? 'pass' : ''
                    }`}
                  >
                    4
                  </span>
                  <span
                    className={`bs-stepper-label font-weight-normal ${
                      3 < currentStep ? 'pass' : ''
                    }`}
                  >
                    Sr. Consultant
                  </span>
                </button>
              </div>
              <div className={`bsline ${3 < currentStep ? 'pass' : ''}`}></div>
              <div className="step" data-target="#test-l-5">
                <button
                  className="step-trigger"
                  onClick={() =>
                    this.setState({
                      currentStep: 4,
                    })
                  }
                >
                  <span
                    className={`bs-stepper-circle font-weight-normal ${
                      4 < currentStep ? 'pass' : ''
                    }`}
                  >
                    5
                  </span>
                  <span
                    className={`bs-stepper-label font-weight-normal ${
                      4 < currentStep ? 'pass' : ''
                    }`}
                  >
                    Manager
                  </span>
                </button>
              </div>
              <div className={`bsline ${4 < currentStep ? 'pass' : ''}`}></div>
              <div className="step" data-target="#test-l-6">
                <button
                  className="step-trigger"
                  onClick={() =>
                    this.setState({
                      currentStep: 5,
                    })
                  }
                >
                  <span
                    className={`bs-stepper-circle font-weight-normal ${
                      5 < currentStep ? 'pass' : ''
                    }`}
                  >
                    6
                  </span>
                  <span
                    className={`bs-stepper-label font-weight-normal ${
                      5 < currentStep ? 'pass' : ''
                    }`}
                  >
                    Partner
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="bs-stepper-content" style={{ minHeight: '315px' }}>
            <div id="test-l-1" className="content">
              {currentStep === 0 ? (
                <Observer onChange={this.animateStep}>
                  <Row className="mt-lg-5">
                    <Col xs={12} md={6}>
                      <div className="wrapper px-0">
                        <div className="page_heading_section text-center">
                          <h3 className="page_heading text-uppercase font-weight-normal d-lg-block d-none white stepper-title">
                            Associate
                          </h3>
                          <h4 className="text-uppercase page_heading_child font-weight-bold text-white stepper-subtitle">
                            Associate
                          </h4>
                        </div>
                        <div
                          id="stapper-description"
                          className="page_heading_section text-center d-none d-lg-block"
                        >
                          <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                            <div>
                              <h5 className="text-white mb-0">What to expect as an associate</h5>
                            </div>
                            <div className="d-flex justify-content-center pl-4 white-learnmore">
                              <div className="py-3">
                                <Link
                                  to="/what-can-i-expect-as-an-associate/"
                                  className="bordered-btn text-center"
                                >
                                  Learn more{' '}
                                  <ReadMore
                                    className="arrow-translate mb-0"
                                    height="10px"
                                    width="40px"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="ml-2 ml-lg-5">
                        <div
                          className="position-relative line line-horizontal"
                          style={{ background: '#0a4169' }}
                        >
                          <YellowKey />
                        </div>
                        <div className="circle-light-bullets">
                          <ul id="lists" className="text-lightblue" style={{ paddingLeft: '23px' }}>
                            <li id="list" className="my-1 pl-2">
                              Takes initiative in project management and collaborates effectively
                              with internal/external partners
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Summarizes/synthesizes research notes/analysis in summary of key
                              insights
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Contributes to development of project materials and client
                              deliverables
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="page_heading_section text-center d-lg-none d-block">
                        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-2">
                          <div>
                            <h5 className="text-white mb-0">What to expect as an associate</h5>
                          </div>
                          <div className="d-flex justify-content-center white-learnmore">
                            <div className="py-3">
                              <Link
                                to="/what-can-i-expect-as-an-associate/"
                                className="bordered-btn text-center"
                              >
                                Learn more{' '}
                                <ReadMore
                                  className="arrow-translate mb-0"
                                  height="10px"
                                  width="40px"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Observer>
              ) : null}
            </div>
            <div id="test-l-2" className="content">
              {currentStep === 1 ? (
                <Observer onChange={this.animateStepOne}>
                  <Row className="mt-lg-5">
                    <Col xs={12} md={6}>
                      <div className="wrapper px-0">
                        <div className="page_heading_section text-center">
                          <h3 className="page_heading text-uppercase font-weight-normal d-lg-block d-none white stepper-title">
                            Sr. Associate
                          </h3>
                          <h4 className="text-uppercase page_heading_child font-weight-bold text-white stepper-subtitle">
                            Sr. Associate
                          </h4>
                        </div>
                        <div
                          id="stapper-description"
                          className="page_heading_section text-center d-none d-lg-block"
                        >
                          <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                            <div>
                              <h5 className="text-white mb-0">What to expect as an associate</h5>
                            </div>
                            <div className="d-flex justify-content-center white-learnmore pl-lg-4">
                              <div className="py-3">
                                <Link
                                  to="/what-can-i-expect-as-an-associate/"
                                  className="bordered-btn text-center"
                                >
                                  Learn more{' '}
                                  <ReadMore
                                    className="arrow-translate mb-0"
                                    height="10px"
                                    width="40px"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="ml-2 ml-lg-5">
                        <div
                          className="position-relative line line-horizontal"
                          style={{ background: '#0a4169' }}
                        >
                          <YellowKey />
                        </div>

                        <div className="circle-light-bullets">
                          <ul id="lists" className="text-lightblue" style={{ paddingLeft: '23px' }}>
                            <li id="list" className="my-1 pl-2">
                              Clearly and succinctly summarizes and visualizes key insights
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Demonstrates critical and independent thinking beyond the task at hand
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Demonstrates a command and clarity in representing one’s own work
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="text-center d-lg-none d-block">
                        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-2">
                          <div>
                            <h5 className="text-white mb-0">What to expect as an associate</h5>
                          </div>
                          <div className="d-flex justify-content-center white-learnmore">
                            <div className="py-3">
                              <Link
                                to="/what-can-i-expect-as-an-associate/"
                                className="bordered-btn text-center"
                              >
                                Learn more{' '}
                                <ReadMore
                                  className="arrow-translate mb-0"
                                  height="10px"
                                  width="40px"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Observer>
              ) : null}
            </div>
            <div id="test-l-3" className="content text-center">
              {currentStep === 2 ? (
                <Observer onChange={this.animateStepTwo}>
                  <Row className="mt-lg-5">
                    <Col xs={12} md={6}>
                      <div className="wrapper px-0">
                        <div className="page_heading_section text-center">
                          <h3 className="page_heading text-uppercase font-weight-normal d-lg-block d-none white stepper-title">
                            Consultant
                          </h3>
                          <h4 className="text-uppercase page_heading_child font-weight-bold text-white stepper-subtitle">
                            Consultant
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="ml-2 ml-lg-5 text-left">
                        <div
                          className="position-relative line line-horizontal "
                          style={{ background: '#0a4169' }}
                        >
                          <YellowKey />
                        </div>
                        <div className="circle-light-bullets">
                          <ul id="lists" className="text-lightblue" style={{ paddingLeft: '23px' }}>
                            <li id="list" className="my-1 pl-2">
                              Increasing client interaction and responsibility for shaping
                              client-ready materials
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Leads/manages internal and external resources across all aspects of
                              project execution
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Contributes to development of associates, as well as the company’s
                              internal initiatives
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Observer>
              ) : null}
            </div>
            <div id="test-l-4" className="content text-center">
              {currentStep === 3 ? (
                <Observer onChange={this.animateStepThree}>
                  <Row className="mt-lg-5">
                    <Col xs={12} md={6}>
                      <div className="wrapper px-0">
                        <div className="page_heading_section text-center">
                          <h3 className="page_heading text-uppercase font-weight-normal d-lg-block d-none white stepper-title">
                            Sr. Consultant
                          </h3>
                          <h4 className="text-uppercase page_heading_child font-weight-bold text-white stepper-subtitle">
                            Sr. Consultant
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="ml-2 ml-lg-5 text-left">
                        <div
                          className="position-relative line line-horizontal"
                          style={{ background: '#0a4169' }}
                        >
                          <YellowKey />
                        </div>
                        <div className="circle-light-bullets">
                          <ul id="lists" className="text-lightblue" style={{ paddingLeft: '23px' }}>
                            <li id="list" className="my-1 pl-2">
                              Independent client interaction/management and ability to balance
                              competing priorities
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Versatility and command across research methods
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Strong catalyst for clients returning
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Material contribution to internal company initiatives
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Observer>
              ) : null}
            </div>
            <div id="test-l-5" className="content text-center">
              {currentStep === 4 ? (
                <Observer onChange={this.animateStepFour}>
                  <Row className="mt-lg-5">
                    <Col xs={12} md={6}>
                      <div className="wrapper px-0">
                        <div className="page_heading_section text-center">
                          <h3 className="page_heading text-uppercase font-weight-normal d-lg-block d-none white stepper-title">
                            Manager
                          </h3>
                          <h4 className="text-uppercase page_heading_child font-weight-bold text-white stepper-subtitle">
                            Manager
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="ml-2 ml-lg-5 text-left">
                        <div
                          className="position-relative line line-horizontal"
                          style={{ background: '#0a4169' }}
                        >
                          <YellowKey />
                        </div>
                        <div className="circle-light-bullets">
                          <ul id="lists" className="text-lightblue" style={{ paddingLeft: '23px' }}>
                            <li id="list" className="my-1 pl-2">
                              Engagement lead and catalyst for client/project success
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Business development responsibilities driven by product excellence
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Demonstrates an ability to directly/tangibly attract/develop talent
                              base
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Significant leadership in driving internal initiatives
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Observer>
              ) : null}
            </div>
            <div id="test-l-6" className="content text-center">
              {currentStep === 5 ? (
                <Observer onChange={this.animateStepFive}>
                  <Row className="mt-lg-5">
                    <Col xs={12} md={6}>
                      <div className="wrapper px-0">
                        <div className="page_heading_section text-center">
                          <h3 className="page_heading text-uppercase font-weight-normal d-lg-block d-none white stepper-title">
                            Partner
                          </h3>
                          <h4 className="text-uppercase page_heading_child font-weight-bold text-white stepper-subtitle">
                            Partner
                          </h4>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <div className="ml-2 ml-lg-5 text-left">
                        <div
                          className="position-relative line line-horizontal"
                          style={{ background: '#0a4169' }}
                        >
                          <YellowKey />
                        </div>
                        <div className="circle-light-bullets">
                          <ul id="lists" className="text-lightblue" style={{ paddingLeft: '23px' }}>
                            <li id="list" className="my-1 pl-2">
                              Develops and leads client relationships
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Provides established thought leadership within relevant expertise
                              areas
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Identifies organizational needs and leads internal initiatives and
                              capability development
                            </li>
                            <li id="list" className="my-1 pl-2">
                              Shapes company talent base and culture
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Observer>
              ) : null}
            </div>
          </div>
        </div>

        {/* <div style={{ minHeight: '272px' }}></div> */}
        <Row className="mb-4">
          <Col xs={12} md={4}>
            <div className="d-flex justify-content-center">
              <div>
                {currentStep > 0 ? (
                  <div
                    className="mx-2"
                    onClick={() => {
                      this.stepper.previous();
                      this.setState({
                        currentStep: this.stepper._currentIndex - 1,
                      });
                    }}
                  >
                    <PrvBtn style={{ height: '30px', widht: '30px' }} />
                  </div>
                ) : null}
              </div>
              <div>
                {currentStep === 5 ? null : (
                  <div
                    className="mx-2"
                    onClick={() => {
                      this.stepper.next();

                      this.setState({
                        currentStep: this.stepper._currentIndex + 1,
                        clickPrev: true,
                      });
                    }}
                  >
                    <NxtBtn style={{ height: '30px', widht: '30px' }} />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
